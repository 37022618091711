import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import DialogVideoBanner from '../../../../../../organisms/dialog-video-banner/dialog-video-banner'
import { cx } from '@emotion/css'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { Fragment } from 'react'

const BannerDialogVideo = ({
	aboveTheFold,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	content,
	ctas = [],
	sx,
	cssProp,
	className,
	RichContentComponent,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
	image,
	video,
	...props
}) => {
	const links = useLinks([{ type: ctas[0]?.linkType, href: ctas[0]?.link }])

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}
	return (
		<DialogVideoBanner
			aboveTheFold={aboveTheFold}
			sx={{ ...sx, minHeight, width: '100%', height: aboveTheFold ? '100%' : undefined }}
			css={cssProp}
			className={cx('Magento-PageBuilder-Banner-Dialog-Video', className)}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			title={title && RichContentComponent ? <RichContentComponent content={title} component={Fragment} /> : null}
			content={content && RichContentComponent ? <RichContentComponent content={content} component={Fragment} /> : null}
			cta={ctas.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				href: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
			image={image}
			video={video}
			{...props}
		/>
	)
}

export default BannerDialogVideo
