import { node, object } from 'prop-types'
import { Root, OverlayContainer } from './hero.styled'
import { cx } from '@emotion/css'

const Hero = ({
	backgroundComponent,
	className,
	overlayComponent,
	overlayContainerProps,
	horizontalAlign,
	...props
}) => {
	return (
		<Root className={cx('Hero-root', className)} horizontalAlign={horizontalAlign} container {...props}>
			{backgroundComponent}
			{overlayComponent && (
				<OverlayContainer className="overlay-container" horizontalAlign={horizontalAlign} {...overlayContainerProps}>
					{overlayComponent}
				</OverlayContainer>
			)}
		</Root>
	)
}

Hero.defaultProps = {
	horizontalAlign: 'flex-start',
	verticalAlign: 'flex-start',
}

Hero.propTypes = {
	backgroundComponent: node,
	overlayComponent: node,
	overlayContainerProps: object,
}

export default Hero
