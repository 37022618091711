import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import sliderConfigAggregator from './slider/config-aggregator'
import BannerListingShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-listing/banner-listing-shimmer'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerImageTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-text/banner-image-text-shimmer'
import BannerVideoTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-video-text/banner-video-text-shimmer'
import BannerSignShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sign/banner-sign-shimmer'
import BannerProductShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product-shimmer'
import BannerStoriesShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-stories/banner-stories-shimmer'
import BannerDialogVideoShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-dialog-video/banner-dialog-video'
import BannerHistoryShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-history/banner-history-shimmer'
import productsConfigAggregator from './products/config-aggregator'
import ProductsShimmer from './products/products-shimmer'
import SliderShimmer from './slider/slider-shimmer'
import SliderTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-text/slider-text-shimmer'

const Products = dynamic(() => import('./products'), { loading: ProductsShimmer })
const productsConfig = { configAggregator: productsConfigAggregator, component: Products }

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerImageText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-text/banner-image-text'
		),
	{ loading: BannerImageTextShimmer }
)

const BannerVideoText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-video-text/banner-video-text'
		),
	{ loading: BannerVideoTextShimmer }
)

const BannerSign = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sign/banner-sign'
		),
	{ loading: BannerSignShimmer, ssr: false }
)

const BannerProduct = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product'
		),
	{ loading: BannerProductShimmer }
)

const BannerStories = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-stories/banner-stories'
		),
	{ loading: BannerStoriesShimmer }
)

const BannerListing = dynamic(
	() =>
		import(
			'/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-listing/banner-listing'
		),
	{ loading: BannerListingShimmer }
)

const SliderZoom = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-zoom/slider-zoom'
		),
	{ loading: SliderShimmer }
)

const BannerDialogVideo = dynamic(
	() =>
		import(
			'/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-dialog-video/banner-dialog-video'
		),
	{ loading: BannerDialogVideoShimmer }
)

const SliderSideText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-side-text/slider-side-text'
		),
	{ loading: SliderShimmer }
)

const BannerHistory = dynamic(
	() =>
		import(
			'/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-history/banner-history'
		),
	{ loading: BannerHistoryShimmer }
)

const SliderSocialWall = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-social-wall/slider-social-wall'
		),
	{ loading: SliderShimmer }
)

const SliderText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-text/slider-text'
		),
	{ loading: SliderTextShimmer }
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerImageTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerImageText,
}

const bannerVideoTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerVideoText,
}

const bannerSignConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSign,
}

const bannerProductConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerProduct,
}

const bannerStoriesConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerStories,
}

const bannerListingConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerListing,
}

const bannerDialogVideoConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerDialogVideo,
}

const bannerHistoryConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerHistory,
}
const productsTileCarouselConfig = {
	...productsConfig,
	configAggregator: (node, props) => ({
		...productsConfigAggregator(node, props),
		tileVariant: 'carousel',
	}),
}

const sliderZoomConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderZoom,
}

const sliderSideTextConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderSideText,
}

const sliderSocialWallConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderSocialWall,
}

const sliderTextConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderText,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'banner-image-text': bannerImageTextConfig,
	'slide-image-text': bannerImageTextConfig,
	'banner-video-text': bannerVideoTextConfig,
	'slide-video-text': bannerVideoTextConfig,
	'banner-sign': bannerSignConfig,
	'banner-listing': bannerListingConfig,
	'banner-product': bannerProductConfig,
	'products-tile-carousel': productsTileCarouselConfig,
	'banner-stories': bannerStoriesConfig,
	'slide-stories': bannerStoriesConfig,
	'slider-zoom': sliderZoomConfig,
	'banner-dialog-video': bannerDialogVideoConfig,
	'slider-side-text': sliderSideTextConfig,
	'banner-history': bannerHistoryConfig,
	'banner-history-reverse': bannerHistoryConfig,
	'slider-social-wall': sliderSocialWallConfig,
	'slider-text': sliderTextConfig,
}

export default customContentTypes
