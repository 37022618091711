import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import { Title, Subtitle, Description, CtaContainer } from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	margin: 41px 24px;
	left: 0;
	right: 0;
	max-width: 100%;
	bottom: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 100px ${({ horizontalAlign }) => (horizontalAlign ? 'auto' : '140px')};
	}
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: inherit;
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};

		${CtaContainer} {
			gap: 20px;
		}
	}

	&.cta-full-width {
		${OverlayContainer} {
			margin: 0;
			${Title} {
				margin: 0 20px;
			}
			${CtaContainer} {
				width: 100%;
				.MuiLink-root {
					width: 100%;
				}
			}
		}
	}

	&.overlay-filled {
		${OverlayContainer} {
			width: 100%;
			margin: 0;
			background: ${({ theme }) => theme.palette.secondary.main};
			left: 0;
			padding: 20px;
			position: relative;
			${Title} {
				color: ${({ theme }) => theme.palette.primary.main};
			}
			${Subtitle} {
				color: ${({ theme }) => theme.palette.primary.main};
			}
			${Description} {
				color: ${({ theme }) => theme.palette.primary.main};
			}
			${CtaContainer} {
				margin-top: 30px;
				.MuiButton-containedSecondary {
					border-color: ${({ theme }) => theme.palette.primary.main};
					background-color: ${({ theme }) => theme.palette.primary.main};
					color: ${({ theme }) => theme.palette.primary.contrastText};
					&:hover {
						background-color: ${({ theme }) => theme.palette.secondary.main};
						color: ${({ theme }) => theme.palette.secondary.contrastText};
					}
					&:focused {
						background-color: ${({ theme }) => theme.palette.secondary.main};
						color: ${({ theme }) => theme.palette.secondary.contrastText};
					}
					&:active {
						background-color: ${({ theme }) => theme.palette.secondary.main};
						color: ${({ theme }) => theme.palette.secondary.contrastText};
					}
				}
			}
		}
	}
`

export { Root, OverlayContainer }
