import Hero from './hero'
import Grid from '@mui/material/Grid'
import { Container, Title, Subtitle, Description, CtaContainer, Link, Button } from './hero-banner.styled'
import { node, object, shape, arrayOf } from 'prop-types'
import { Parallax } from 'react-scroll-parallax'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { useIsTouchDevice } from '@bluheadless/ui-logic/src/hooks/useIsTouchDevice'

const HeroBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	noParallax = true,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	const isTouchDevice = useIsTouchDevice()

	return (
		<Hero
			overlayComponent={
				<Container>
					{subtitle && (
						<Subtitle variant="subheadline1" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{title && (
						<Parallax easing="easeOut" speed={-3} disabled={noParallax || !mdUp || isTouchDevice}>
							<Title variant="headlinebig" {...titleProps}>
								{title}
							</Title>
						</Parallax>
					)}
					{description && (
						<Description variant="subheadline1" {...descriptionProps}>
							{description}
						</Description>
					)}
					{cta && cta?.length > 0 && (
						<Parallax easing="easeOut" speed={-3} disabled={noParallax || !mdUp || isTouchDevice}>
							<CtaContainer
								display="inline-flex"
								justifyContent={props.horizontalAlign}
								hasSiblings={!!description || !!title}
								{...ctaProps}
							>
								{cta.map(
									({ label, url, ...rest }, key) =>
										label && (
											<Grid item key={key}>
												<Button href={url} {...rest}>
													{label}
												</Button>
											</Grid>
										)
								)}
							</CtaContainer>
						</Parallax>
					)}
					{additionalContent}
				</Container>
			}
			backgroundComponent={
				<Link
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
					}}
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

HeroBanner.defaultProps = {
	...Hero.defaultProps,
}

HeroBanner.propTypes = {
	...Hero.propTypes,
	title: node,
	titleProps: object,
	subtitle: node,
	subtitleProps: object,
	description: node,
	descriptionProps: object,
	cta: arrayOf(shape({ ...Button.propTypes })),
	ctaProps: object,
	additionalContent: node,
}

export default HeroBanner
